import { createRouter, createWebHistory } from 'vue-router'

// var reg = new RegExp("www|sitename|test|localhost:8000");
// var parts = window.location.host.split(".");
// const indexPath = reg.test(parts[0]) ? '../views/IndexPage.vue' : '../views/IndexPage.vue'

const routes = [
  {
    path: '/',
    name: 'IndexPage',
    meta: { requiresAuth: false },
    component: () => import('../views/IndexPage.vue')
  },
  {
    path: '/menu',
    name: 'MenuPage',
    meta: { requiresAuth: false },
    component: () => import('../views/MenuPage.vue')
  },
  {
    path: '/groups/:id',
    name: 'GroupPage',
    component: () => import('../views/groups/GroupStatisticPage.vue')
  },
  {
    path: '/groups/:id/users',
    name: 'GroupUsersPage',
    component: () => import('../views/groups/GroupUsersPage.vue')
  },
  {
    path: '/demo',
    name: 'DemoPage',
    component: () => import('../views/DemoPage.vue')
  },
  {
    path: '/arasaac',
    name: 'ArasaacPage',
    component: () => import('../views/tools/ArasaacPage.vue')
  },
  {
    path: '/keyboard',
    name: 'KeyboardPage',
    component: () => import('../views/tools/KeyboardPage.vue')
  },
  {
    path: '/users/:id/lessons',
    name: 'LessonsPage',
    component: () => import('../views/LessonsPage.vue')
  },
  {
    path: '/users/:id/games/letter-move',
    name: 'LetterMovePage',
    component: () => import('../views/games/LetterMovePage.vue')
  },
  {
    path: '/users/:id/games',
    name: 'GamesPage',
    component: () => import('../views/GamesPage.vue')
  },
  {
    path: '/voices',
    name: 'VoicesPage',
    component: () => import('../views/VoicesPage.vue')
  },
  {
    path: '/dev',
    name: 'DevPage',
    component: () => import('../views/company/DevPage.vue')
  },
  {
    path: '/invite/:hash',
    name: 'InvitePage',
    component: () => import('../views/groups/InvitePage.vue')
  },
  {
    path: '/groups',
    name: 'GroupsPage',
    component: () => import('../views/groups/GroupsPage.vue')
  },
  {
    path: '/groups/:id/edit',
    name: 'GroupEditPage',
    component: () => import('../views/groups/GroupEditPage.vue')
  },
  {
    path: '/config-manage',
    name: 'ManageConfig',
    meta: { requiresAuth: true },
    component: () => import('../views/manage/ManageConfig.vue')
  },
  {
    path: '/tasks',
    name: 'TasksPage',
    component: () => import('../views/tasks/TasksPage.vue')
  },
  {
    path: '/tasks/:set_id',
    name: 'TaskPage',
    component: () => import('../views/tasks/TaskPage.vue')
  },
  {
    path: '/users',
    name: 'UsersPage',
    component: () => import('../views/users/UsersPage.vue')
  },
  {
    path: '/users/:id',
    name: 'UserPage',
    component: () => import('../views/users/UserPage.vue')
  },
  {
    path: '/users/:id/index',
    name: 'UserIndexPage',
    component: () => import('../views/users/UserIndexPage.vue')
  },
  {
    path: '/users/:id/chat',
    name: 'UserChatPage',
    component: () => import('../views/ChatPage.vue')
  },
  {
    path: '/users/:id/sets',
    name: 'UserSetsPage',
    component: () => import('../views/users/cards/UserSetsPage.vue')
  },
  {
    path: '/users/:id/sequences',
    name: 'UserSequencesPage',
    component: () => import('../views/users/cards/UserSetsPage.vue')
  },
  {
    path: '/users/:id/sets/:set_id/manage',
    name: 'UserSetManagePage',
    component: () => import('../views/manage/Cards.vue')
  },
  {
    path: '/users/:id/sets/:set_id',
    name: 'UserSetPage',
    component: () => import('../views/CardsPage.vue')
  },
  {
    path: '/users/:id/categories',
    name: 'UserCategoriesPage',
    component: () => import('../views/users/cards/UserCategoriesPage.vue')
  },
  {
    path: '/users/:id/config',
    name: 'UserConfigPage',
    component: () => import('../views/users/cards/UserConfigPage.vue')
  },
  {
    path: '/users/:id/tasks',
    name: 'UserTasksPage',
    component: () => import('../views/tasks/TasksPage.vue')
  },
  {
    path: '/users/:id/tasks/:set_id',
    name: 'UserTaskPage',
    component: () => import('../views/tasks/TaskPage.vue')
  },
  {
    path: '/users/:id/cards',
    name: 'UserCardsPage',
    component: () => import('../views/CardsPage.vue')
  },
  {
    path: '/users/:id/cards/all',
    name: 'UserAllCardsPage',
    component: () => import('../views/users/UserCards.vue')
  },
  {
    path: '/users/:id/cards/manage',
    name: 'UserCardsManagePage',
    component: () => import('../views/manage/Cards.vue')
  },
  {
    path: '/users/:id/cards/add',
    name: 'UserCardsAddPage',
    component: () => import('../views/users/AddCard.vue')
  },
  {
    path: '/users/:id/statistic',
    name: 'UserStatisticPage',
    component: () => import('../views/StatisticPage.vue')
  },
  {
    path: '/users/:id/jettons',
    name: 'UserJettonsPage',
    component: () => import('../views/JettonsPage.vue')
  },
  {
    path: '/users/:id/edit',
    name: 'UserEditPage',
    component: () => import('../views/users/UserEditPage.vue')
  },
  {
    path: '/users/:id/subscription',
    name: 'UserSubscriptionPage',
    component: () => import('../views/users/UserSubscriptionPage.vue')
  },
  {
    path: '/users/:id/audios',
    name: 'UserAudiosPage',
    component: () => import('../views/users/UserAudiosPage.vue')
  },
  {
    path: '/statistic',
    name: 'StatisticPage',
    meta: { requiresAuth: true },
    component: () => import('../views/StatisticPage.vue')
  },
  {
    path: '/history',
    name: 'HistoryPage',
    component: () => import('../views/ChatPage.vue')
  },
  {
    path: '/cards-manage',
    name: 'ManageCards',
    meta: { requiresAuth: true },
    component: () => import('../views/manage/Cards.vue')
  },
  {
    path: '/terms',
    name: 'TermsPage',
    component: () => import('../views/company/TermsPage.vue')
  },
  {
    path: '/about',
    name: 'AboutPage',
    component: () => import('../views/company/AboutPage.vue')
  },
  {
    path: '/contacts',
    name: 'ContactsPage',
    component: () => import('../views/company/ContactsPage.vue')
  },
  {
    path: '/tariffs',
    name: 'TariffsPage',
    component: () => import('../views/company/TariffsPage.vue')
  },
  {
    path: '/donations',
    name: 'DonationsPage',
    component: () => import('../views/company/DonationsPage.vue')
  },
  {
    path: '/donations/done',
    name: 'DonationsDonePage',
    component: () => import('../views/company/DonationsDonePage.vue')
  },
  {
    path: '/tutorials',
    name: 'TutorialsPage',
    component: () => import('../views/tutorials/TutorialsPage.vue')
  },
  {
    path: '/tutorials/:id',
    name: 'TutorialPage',
    component: () => import('../views/tutorials/TutorialPage.vue')
  },
  {
    path: '/tutorials/:id/edit',
    name: 'TutorialEditPage',
    component: () => import('../views/tutorials/TutorialEditPage.vue')
  },
  {
    path: '/video',
    name: 'VideoPage',
    meta: { requiresAuth: true },
    component: () => import('../views/VideoPage.vue')
  },
  {
    path: '/record',
    name: 'RecorderPage',
    meta: { requiresAuth: false },
    component: () => import('../views/RecorderPage.vue')
  },
  {
    path: '/words',
    name: 'WordsPage',
    meta: { requiresAuth: false },
    component: () => import('../views/games/WordsPage.vue')
  },
  {
    path: '/world',
    name: 'WorldPage',
    meta: { requiresAuth: false },
    component: () => import('../views/WorldPage.vue')
  },
  {
    path: '/cards',
    name: 'CardsPage',
    meta: { requiresAuth: false },
    component: () => import('../views/CardsPage.vue')
  },
  {
    path: '/cards2',
    name: 'Cards2Page',
    meta: { requiresAuth: false },
    component: () => import('../views/Cards2PageOld.vue')
  },
  {
    path: '/stream',
    name: 'StreamPage',
    meta: { requiresAuth: true },
    component: () => import('../views/StreamPage.vue')
  },
  {
    path: '/stream-o',
    name: 'StreamOPage',
    meta: { requiresAuth: true },
    component: () => import('../views/StreamOPage.vue')
  },
  {
    path: '/schedule',
    name: 'SchedulePage',
    meta: { requiresAuth: false },
    component: () => import('../views/SchedulePage.vue')
  },

  {
    path: '/games/repka',
    name: 'GameRepka',
    meta: { requiresAuth: true },
    component: () => import('../views/games/RepkaPage.vue')
  },
  {
    path: '/games/paint',
    name: 'GamePaint',
    meta: { requiresAuth: true },
    component: () => import('../views/games/PaintPage.vue')
  },
  {
    path: '/games/puzzle',
    name: 'PuzzlePage',
    meta: { requiresAuth: false },
    component: () => import('../views/games/PuzzlePage.vue')
  },
  {
    path: '/games/graph-pass',
    name: 'GraphPassPage',
    meta: { requiresAuth: false },
    component: () => import('../views/games/GraphPassPage.vue')
  },
  {
    path: '/games/letter-cards',
    name: 'GameLetterCards',
    meta: { requiresAuth: false },
    component: () => import('../views/games/LetterCards.vue')
  },
  {
    path: '/exercises/letters',
    name: 'ExerciseLetters',
    meta: { requiresAuth: false },
    component: () => import('../views/exercises/LettersPage.vue')
  },
  {
    path: '/exercises/dtt',
    name: 'DTTPage',
    meta: { requiresAuth: false },
    component: () => import('../views/exercises/DTT.vue')
  },
  {
    path: '/exercises/dtt2',
    name: 'DTTPage2',
    meta: { requiresAuth: false },
    component: () => import('../views/exercises/DTT2.vue')
  },
  {
    path: '/exercises/vp',
    name: 'VPPage',
    meta: { requiresAuth: false },
    component: () => import('../views/exercises/VP.vue')
  },
  {
    path: '/games/cards',
    name: 'GameCardsPage',
    meta: { requiresAuth: false },
    component: () => import('../views/games/GameCardsPage.vue')
  },
  {
    path: '/games/yes-no',
    name: 'GameYesNo',
    meta: { requiresAuth: false },
    component: () => import('../views/games/GameYesNo.vue')
  },
  {
    path: '/auth',
    name: 'AuthPage',
    meta: { requiresAuth: false },
    component: () => import('../views/AuthPage.vue')
  },
  {
    path: '/manage/tree',
    name: 'ManageTree',
    meta: { requiresAuth: true },
    component: () => import('../views/manage/Tree.vue')
  },
  {
    path: '/manage/games/paint',
    name: 'ManageGamePaint',
    meta: { requiresAuth: true },
    component: () => import('../views/manage/games/ManagePaint.vue')
  },
  {
    path: '/manage/lessons',
    name: 'ManageLessons',
    meta: { requiresAuth: true },
    component: () => import('../views/manage/ManageLessons.vue')
  },
  {
    path: '/manage/movies',
    name: 'ManageMovies',
    meta: { requiresAuth: true },
    component: () => import('../views/manage/ManageMovies.vue')
  },
  {
    path: '/manage/schedule',
    name: 'ManageSchedule',
    meta: { requiresAuth: true },
    component: () => import('../views/manage/ManageSchedule.vue')
  },
  {
    path: '/manage/exercises',
    name: 'ManageExercises',
    meta: { requiresAuth: true },
    component: () => import('../views/manage/ManageExercises.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  mode: 'history',
  routes,
  scrollBehavior() {
    // scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  }
})

export default router
